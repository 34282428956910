

































































































import {defineComponent, ref, Ref, computed, onMounted} from '@vue/composition-api';
import Date from '@/components/DateTime/Calendar.vue';
import AccountFilterIcon from "@/components/AccountFilterIcon.vue";
import ParticipantList from "@/components/ParticipantList.vue";
import ConversationTime from "@/components/DateTime/ConversationTime.vue";
import ConversationOptions from "@/components/PollOptions.vue";
import {getPoll, PollView, updatePoll} from "@/backend/poll";
import {savingPromise} from "@/util/savigIndicator";
import {createQuestion, deleteQuestion} from "@/backend/question";
import Question from "@/components/Question.vue";
import {selectContents} from "@/util/util";
import ContactLists from "@/components/ContactLists.vue";
import TimeInput from "@/components/DateTime/TimeInput.vue";
import DateTimeInput from "@/components/DateTime/DateTimeInput.vue";
import moment from 'moment';
import 'moment-timezone';
import Editor from "@/components/Editor.vue";

export default defineComponent({
  name: 'Poll',
  components: {
    Editor,
    DateTimeInput,
    TimeInput,
    ContactLists, Question, ConversationOptions, ConversationTime, ParticipantList, Date, AccountFilterIcon
  },
  props: {
    requireSummary: Boolean,
    title: String
  },
  setup(_, {root}) {
    const poll = ref<PollView>() as Ref<PollView>;
    const pollLoading = ref(false);


    const saveName = async (event: Event) => {
      const name = (event.target as HTMLElement).innerText.trim();
      if (name === poll.value.name) return;
      const updated = await savingPromise(updatePoll(poll.value._id, {name}));
      poll.value.name = updated.name;
    }

    const saveDescription = async (event: Event) => {
      const description = (event.target as HTMLElement).innerText.trim();
      if (description === poll.value.description) return;
      const updated = await savingPromise(updatePoll(poll.value._id, {description}));
      poll.value.description = updated.description;
    }

    const newQuestion = async () => {
      const question = await savingPromise(createQuestion(poll.value._id));
      poll.value.questions.push(question);
    }

    const removeQuestion = async (id: string) => {
      await savingPromise(deleteQuestion(id));
      poll.value.questions = poll.value.questions.filter(question => question._id !== id);
    }

    const step = ref(1);
    const redirectUrl = computed(() => root.$route.path + '?step=' + step.value);

    const saveParticipantLists = async (lists: string[]) => {
      const updated = await savingPromise(updatePoll(poll.value._id, {
        participantLists: lists
      }));
      poll.value.participantLists = updated.participantLists;
    }

    const currentDateTime = moment();
    const startDateTime = ref(currentDateTime);
    const endDateTime = ref(currentDateTime.clone().add(1, 'days'));
    const validDuration = computed(() => startDateTime.value && endDateTime.value);

    const saveDuration = async () => {
      const updated = await savingPromise(updatePoll(poll.value._id, {
        duration: {start: startDateTime.value.clone().utc().format(), end: endDateTime.value.clone().utc().format()}
      }));
      root.$router.push({name: 'Poll', params: {id: updated._id}})
    }

    const refreshPoll = async () => {
      try {
        pollLoading.value = true;
        poll.value = await getPoll(root.$route.params.id);
        if (!poll.value.questions.length) newQuestion();
        if (poll.value.duration) {
          startDateTime.value = moment(poll.value.duration.start);
          endDateTime.value = moment(poll.value.duration.end);
        }
      } finally {
        pollLoading.value = false;
      }
    }
    refreshPoll();
    return {
      poll, pollLoading, saveName, saveDescription, newQuestion, removeQuestion, selectContents, step,
      redirectUrl, saveParticipantLists, startDateTime, endDateTime, currentDateTime, validDuration, saveDuration
    };
  },
});

