










import {defineComponent} from '@vue/composition-api';
import ContactLists from "@/components/ContactLists.vue";

export default defineComponent({
  name: 'ContactListsView',
  components: {ContactLists}
});

