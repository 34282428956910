



















import {defineComponent, ref} from '@vue/composition-api';
import {googleSignIn} from "@/auth/auth";
import {displayError} from "@/util/error";

export default defineComponent({
  name: 'Login',
  setup(props, {root}) {
    const loading = ref(false);
    const signIn = async () => {
      loading.value = true;
      try {
        await googleSignIn();
        await root.$router.push({name: 'Home'});
      } catch (e) {
        console.log(e);
        displayError('Failed to log in: ' + e.error);
      }
      loading.value = false;
    }
    return {signIn, loading};
  },
});
