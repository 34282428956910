









import {defineComponent} from '@vue/composition-api';
import moment from 'moment';
import 'moment-timezone';
import Calendar from "@/components/DateTime/Calendar.vue";

export default defineComponent({
  name: 'ConversationTime',
  components: {Calendar},
  props: {
    value: {type: Object, required: true}
  },
  setup({value}) {
    return {duration: moment.duration(moment(value.end).diff(moment(value.start))).humanize()};
  },
});
