export function ignore() {
}

export function mod(n: number, m: number) {
    return ((n % m) + m) % m;
}

export function asArray<T>(items: T | T[]){
    return ([] as T[]).concat(items);
}

export function selectContents(e: Event | Node) {
    if ('target' in e) e = e.target as Node;
    const range = document.createRange();
    range.selectNodeContents(e);
    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);
}

