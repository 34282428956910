import axios from "axios";
import {List} from "@noty/common";

export {List};

export async function createList(name = 'Untitled list'): Promise<List> {
    return (await axios.post<List>('lists', {name})).data;
}

export async function updateList(id: string, update: { name: string }) {
    return (await axios.patch<List>('lists/' + id, update)).data;
}