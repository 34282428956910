import axios from "axios";
import {ParticipantView, Poll, PollView, PollState, Vote} from "@noty/common";

export {Poll, PollView, Vote, PollState};

export async function createPoll(name = 'Untitled poll'): Promise<Poll> {
    return (await axios.post<Poll>('polls', {name})).data;
}

export async function getPolls() {
    return (await axios.get<Poll[]>('polls')).data;
}

export async function getPoll(id: string) {
    return (await axios.get<PollView>('polls/' + id)).data;
}

export async function getPollParticipants(id: string) {
    return (await axios.get<ParticipantView[]>('polls/' + id + '/participants')).data;
}

export async function getPollVotes(id: string) {
    return (await axios.get<Vote[]>('polls/' + id + '/votes')).data;
}

export async function sendPollInvites(id: string, contacts: string[]) {
    return (await axios.post<PollView>('polls/' + id + '/invite', contacts)).data;
}

export async function savePollVote(id: string, vote: Omit<Omit<Vote, '_id'>, 'dateTime'>) {
    return (await axios.post<Vote>('polls/' + id + '/vote', vote)).data;
}

export async function getPollVote(id: string, email: string, code: string) {
    return (await axios.get<Vote>('polls/' + id + '/vote', {params: {email, code}})).data;
}

export async function deletePoll(id: string) {
    return (await axios.delete<Poll>('polls/' + id)).data;
}

type PollUpdate = {
    name?: string,
    description?: string,
    mailHtml?: string,
    participantLists?: string[],
    duration?: { start: string, end: string }
}

export async function updatePoll(id: string, update: PollUpdate) {
    return (await axios.patch<Poll>('polls/' + id, update)).data;
}

