


























import {defineComponent, ref, computed} from '@vue/composition-api';
import moment from 'moment';
import 'moment-timezone';
import TimeInput from "@/components/DateTime/TimeInput.vue";

export default defineComponent({
  name: 'DateTimeInput',
  components: {TimeInput},
  props: {
    label: String,
    min: Object,
    value: Object
  },
  setup(props, {emit}) {
    const dateTime = ref(props.value || moment());
    const menu = ref();
    const minError = computed(() => props.min && dateTime.value.isBefore(props.min));
    const emitValue = () => {
      emit('input', minError.value ? null : dateTime.value);
    }
    const updateDate = (v: string) => {
      dateTime.value = moment(v + ' ' + dateTime.value.format('HH:mm'));
      menu.value = false;
      emitValue();
    }
    const updateTime = (v: string) => {
      dateTime.value = moment(dateTime.value.format('YYYY-MM-DD') + ' ' + v);
      emitValue();
    }
    return {dateTime, minError, updateDate, updateTime, menu};
  },
});
