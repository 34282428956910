






























































































































































































import {defineComponent, ref, Ref, computed} from '@vue/composition-api';
import {jsonToHtml, ParsedSheet, readSpreadsheetFile, SheetRow} from "@/util/sheet";
import {rules} from "@/util/rules";
import {Contact, importContacts} from '@/backend/contact';
import ContactTable from "@/components/ContactTable.vue";

export default defineComponent({
  name: 'ImportContacts',
  components: {ContactTable},
  props: {
    list: {
      required: true,
      type: String
    }
  },
  setup(props, {emit}) {
    const importContactsDialog = ref(false);
    const fileInput = ref();
    const handleFile = async (e: any) => {
      parsedSheets.value = await readSpreadsheetFile(e.target.files[0]);
      importContactsDialog.value = true;
      e.target.value = null;
    }
    const parsedSheets = ref();
    const sheetIndex = ref();
    const selectedSheet = ref() as Ref<ParsedSheet>;

    const step = ref(1);

    //data mapping
    const handleColumn = (notValidRows: Ref<SheetRow[]>, validValues: Ref<string[]>, header: string, email = false) => {
      notValidRows.value = [];
      validValues.value = [];
      selectedSheet.value.data.forEach((row: Record<string, string>) => {
        const value = row[header];
        if (value && (!email || rules.email(value) === true)) validValues.value.push(value);
        else notValidRows.value.push(row);
      });
    }

    const emailColumn = ref();
    const notValidEmailsRows = ref<SheetRow[]>([]);
    const validEmails = ref<string[]>([]);
    const handleEmailColumn = () => {
      handleColumn(notValidEmailsRows, validEmails, emailColumn.value, true);
    }

    const firstNameColumn = ref();
    const emptyFirstNameRows = ref<SheetRow[]>([]);
    const validFirstNames = ref<string[]>([]);
    const handleFirstNameColumn = () => {
      handleColumn(emptyFirstNameRows, validFirstNames, firstNameColumn.value);
    }

    const lastNameColumn = ref();
    const emptyLastNameRows = ref<SheetRow[]>([]);
    const validLastNames = ref<string[]>([]);
    const handleLastNameColumn = () => {
      handleColumn(emptyLastNameRows, validLastNames, lastNameColumn.value);
    }

    const patronymicNameColumn = ref();
    const validPatronymicNames = ref<string[]>([]);
    const handlePatronymicNameColumn = () => {
      validPatronymicNames.value = [];
      selectedSheet.value.data.forEach((row: SheetRow) => {
        const value = row[patronymicNameColumn.value];
        validPatronymicNames.value.push(value);
      });
    }

    const rawRowToContact = (row: SheetRow) => {
      const contact: Contact = {
        email: row[emailColumn.value],
        firstName: row[firstNameColumn.value],
        lastName: row[lastNameColumn.value]
      } as Contact;
      if (patronymicNameColumn.value) contact.patronymicName = row[patronymicNameColumn.value];
      return contact;
    }

    const notValidContacts = computed(() => {
      const notValidRows = new Set<SheetRow>();
      notValidEmailsRows.value.forEach(row => notValidRows.add(row));
      emptyFirstNameRows.value.forEach(row => notValidRows.add(row));
      emptyLastNameRows.value.forEach(row => notValidRows.add(row));
      return Array.from(notValidRows);
    });

    const notValidPreview = computed(() => jsonToHtml(notValidContacts.value));

    const showPreview = ref(false);
    const showSkipped = ref(false);

    const form = ref();
    const finishDataMapping = () => {
      if (!form.value.validate()) return;
      step.value++;
    }

    //finalizing import
    const contactsPreview = computed(() => {
      if (!selectedSheet.value) return [];
      const validSet = new Set(selectedSheet.value.data);
      notValidContacts.value.forEach(row => validSet.delete(row));
      return Array.from(validSet).map(rawRowToContact);
    });

    const updateAttributes = ref(true);
    const conditionsConfirmation = ref(false);

    const importLoading = ref(false);
    const confirmImport = async () => {
      importLoading.value = true;
      try {
        emit('import', await importContacts(contactsPreview.value, props.list, updateAttributes.value));
        importContactsDialog.value = false;
      } finally {
        importLoading.value = false;
      }
    }

    return {
      importContactsDialog, fileInput, handleFile, parsedSheets, sheetIndex, selectedSheet, step, rules,
      emailColumn, notValidEmailsRows, validEmails, handleEmailColumn,
      firstNameColumn, emptyFirstNameRows, validFirstNames, handleFirstNameColumn,
      lastNameColumn, emptyLastNameRows, validLastNames, handleLastNameColumn,
      patronymicNameColumn, validPatronymicNames, handlePatronymicNameColumn,
      notValidContacts, notValidPreview, showPreview, showSkipped, form, finishDataMapping,
      contactsPreview, updateAttributes, conditionsConfirmation, importLoading, confirmImport
    }
  },
});

