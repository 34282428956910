































import {defineComponent, ref, computed} from '@vue/composition-api';
import {getColorMap} from "@/util/colorMap";

export default defineComponent({
  name: 'ParticipantList',
  props: {
    value: {type: Array as () => string[], required: true},
    colorMap: Object,
    selectable: Boolean
  },
  setup(props, {emit}) {
    const colors = computed(() => props.colorMap ?? getColorMap(props.value));

    const selected = ref(new Set<string>());
    const toggle = (name: string) => {
      if (!props.selectable) return;
      if (selected.value.has(name)) selected.value.delete(name);
      else selected.value.add(name);
      selected.value = new Set(selected.value);
    }

    const applyParticipantsFilter = () => emit('selected', selected.value);
    const clearParticipantsFilter = () => {
      selected.value = new Set();
      applyParticipantsFilter();
    }

    return {colors, selected, toggle, applyParticipantsFilter, clearParticipantsFilter};
  }
});
