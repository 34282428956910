






















































import { defineComponent, ref, Ref, computed } from '@vue/composition-api';
import Date from '@/components/DateTime/Calendar.vue';
import AccountFilterIcon from "@/components/AccountFilterIcon.vue";
import ParticipantList from "@/components/ParticipantList.vue";
import ConversationTime from "@/components/DateTime/ConversationTime.vue";
import ConversationOptions from "@/components/PollOptions.vue";
import { getPoll, PollView, Vote, savePollVote, getPollVote } from "@/backend/poll";
import { Contact } from "@/backend/contact";
import Question from "@/components/Question.vue";
import { Question as QuestionType } from '@/backend/question';
import ContactLists from "@/components/ContactLists.vue";
import TimeInput from "@/components/DateTime/TimeInput.vue";
import DateTimeInput from "@/components/DateTime/DateTimeInput.vue";
import PollState from "@/components/PollState.vue";
import { Vue } from "vue-property-decorator";

export default defineComponent({
  name: 'PollVote',
  components: {
    PollState,
    DateTimeInput,
    TimeInput,
    ContactLists, Question, ConversationOptions, ConversationTime, ParticipantList, Date, AccountFilterIcon
  },
  props: {
    requireSummary: Boolean,
    title: String
  },
  setup(_, { root }) {
    const poll = ref<PollView>() as Ref<PollView>;
    const vote = ref<Vote>() as Ref<Vote>;
    const pollLoading = ref(false);

    const email = root.$route.query.email as string;
    const code = root.$route.query.code as string;

    const confirmDialog = ref(false);
    const choices = ref({}) as Ref<Record<string, {
      question: string,
      options: number[]
    }>>;
    const allVoted = computed(() => {
      return Object.values(choices.value).filter(choices => choices.options.length).length === poll.value.questions.length;
    });
    const registerVote = (question: QuestionType, choice: any) => {
      Vue.set(choices.value, question._id, { question: question._id, options: choice });
    }
    const saveVote = async () => {
      vote.value = await savePollVote(poll.value._id, {
        choices: Object.values(choices.value),
        contact: { email } as Contact,
        poll: poll.value._id,
        auth: { code }
      });
      confirmDialog.value = false;
    }

    const votedOptions = (question: QuestionType) => {
      if (!vote.value) return [];
      return vote.value.choices.find(choice => choice.question === question._id)?.options;
    };

    const refreshPoll = async () => {
      try {
        pollLoading.value = true;
        vote.value = await getPollVote(root.$route.params.id, email, code);
        poll.value = await getPoll(root.$route.params.id);
      } finally {
        pollLoading.value = false;
      }
    }
    refreshPoll();
    return {
      poll, vote, pollLoading, choices, allVoted, registerVote, confirmDialog, saveVote, votedOptions
    };
  },
});

