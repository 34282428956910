



























































import {defineComponent, ref, Ref} from '@vue/composition-api';
import {Contact, ContactList, getContact, getContactByEmail, getContactLists, updateContact} from '@/backend/contact';
import {selectContents} from "@/util/util";
import {rules} from "@/util/rules";
import ImportContacts from "@/components/ImportContacts.vue";

export default defineComponent({
  name: 'Contact',
  components: {ImportContacts},
  setup(_, {root}) {
    const contact = ref<Contact>() as Ref<Contact>;
    const contactLists = ref<ContactList[]>([]);
    let currentEmail = '';
    const contactLoading = ref(false);
    const refreshContact = async () => {
      try {
        contactLoading.value = true;
        contact.value = await getContact(root.$route.params.id);
        //@ts-expect-error TODO fix
        contact.value.lists = contact.value.lists.map(list => list._id);
        contactLists.value = await getContactLists();
        currentEmail = contact.value.email;
      } finally {
        contactLoading.value = false;
      }
    }
    refreshContact();

    const existingContact = ref<Contact>();
    const validateContactNotExist = async () => {
      if (contact.value.email === currentEmail) existingContact.value = undefined;
      else existingContact.value = await getContactByEmail(contact.value.email);
    }

    const form = ref();
    const saveContactLoading = ref(false);
    const saveContact = async () => {
      if (!form.value.validate()) return;
      try {
        saveContactLoading.value = true;
        contact.value = await updateContact(contact.value._id, contact.value);
        currentEmail = contact.value.email;
      } finally {
        saveContactLoading.value = false;
      }
    }

    return {
      contact, contactLists, existingContact, validateContactNotExist, form, saveContactLoading, saveContact,
      selectContents, rules
    };
  },
});

