













import {defineComponent} from '@vue/composition-api';
import moment from 'moment';
import 'moment-timezone';

export default defineComponent({
  name: 'PollState',
  props: {
    value: {required: true, type: Object}
  },
  setup(props) {
    if (!props.value.duration) return {start: undefined, end: undefined};
    const start = moment(props.value.duration.start);
    const end = moment(props.value.duration.end);
    return {start, end};
  },
});

