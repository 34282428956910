

































































import {defineComponent, ref, Ref} from '@vue/composition-api';
import {Contact, ContactList, createContact, getContactByEmail, getContactList} from '@/backend/contact';
import {updateList} from "@/backend/list";
import {selectContents, asArray} from "@/util/util";
import {rules} from "@/util/rules";
import ImportContacts from "@/components/ImportContacts.vue";
import {Vue} from "vue-property-decorator";

export default defineComponent({
  name: 'ContactList',
  components: {ImportContacts},
  setup(_, {root}) {
    const headers = [
      {text: 'Email', value: 'email'},
      {text: 'First name', value: 'firstName'},
      {text: 'Last Name', value: 'lastName'},
      {text: 'Patronymic name', value: 'patronymicName'}
    ]
    const list = ref<ContactList>() as Ref<ContactList>;
    const listLoading = ref(false);
    const refreshList = async () => {
      try {
        listLoading.value = true;
        list.value = await getContactList(root.$route.params.id);
      } finally {
        listLoading.value = false;
      }
    }
    refreshList();

    const saveName = async (event: Event) => {
      const name = (event.target as HTMLElement).innerText.trim();
      if (name === list.value.name) return;
      const updated = await updateList(list.value._id, {name});
      list.value.name = updated.name;
    }

    // adding contact
    const appendContacts = (contacts: Contact | Contact[]) => {
      asArray(contacts).forEach(contact => {
        const index = list.value.contacts.findIndex(c => c.email === contact.email);
        if (index >= 0) Vue.set(list.value.contacts, index, contact);
        else list.value.contacts.push(contact);
      });
    }
    const addContactDialog = ref(false);
    const newContact = ref({}) as Ref<Contact>;
    const existingContact = ref<Contact>();
    const validateContactNotExist = async () => existingContact.value = await getContactByEmail(newContact.value.email);
    const form = ref();
    const addContactLoading = ref(false);
    const addContact = async () => {
      if (!form.value.validate()) return;
      try {
        addContactLoading.value = true;
        newContact.value.lists = [list.value._id];
        appendContacts(await createContact(newContact.value));
        newContact.value = {} as Contact;
      } finally {
        addContactLoading.value = false;
      }
    }

    const openContact = (contact: Contact) => {
      root.$router.push({name: 'Contact', params: {id: contact._id}});
    }

    return {
      headers, list, saveName,
      appendContacts, addContactDialog, newContact, validateContactNotExist,
      existingContact, form, addContactLoading, addContact, openContact,
      selectContents, rules
    };
  },
});

