function twoDigits(n: number) {
    return n.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
    })
}

function createTimeArray() {
    const array: string[] = [];
    for (let hour = 0;  hour < 24; hour++)
        array.push(twoDigits(hour) + ':00', twoDigits(hour) + ':30');
    return array;
}

export const timesArray = createTimeArray();