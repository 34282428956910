// https://www.regexpal.com/97668
const emailRegex = /^([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])$/;

export const rules = {
    notEmpty: (v:string) => !!v || 'This value is required',
    email: (v:string) => emailRegex.test(v) || 'Email must be valid',
    time: (v:string) => {
        try{
            const [hours, minutes] = v.split(':');
            return parseInt(hours) < 24 && parseInt(minutes) < 60 || 'Invalid time';
        }catch (e){
            return 'Invalid time'
        }
    }
}
