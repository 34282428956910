










































import {defineComponent, ref, PropType, computed} from '@vue/composition-api';
import {getCurrentUser} from "@/auth/identity";
import {ContactList, getContactLists} from "@/backend/contact";
import {createList, List} from "@/backend/list";

export default defineComponent({
  name: 'ContactLists',
  props: {
    selectable: Boolean,
    clickable: Boolean,
    value: Array as PropType<string[]>
  },
  setup(props, {root}) {
    const headers = [
      {text: 'List', value: 'name'},
      {text: 'Number of contacts', value: 'contacts.length'}
    ];

    const user = getCurrentUser();
    const contactLists = ref<ContactList[]>([]);
    const contactListsLoading = ref(false);
    const refreshContactLists = async () => {
      try {
        contactListsLoading.value = true;
        contactLists.value = await getContactLists();
      } finally {
        contactListsLoading.value = false;
      }
    }
    refreshContactLists();


    const newListLoading = ref(false);
    const newContactList = async () => {
      try {
        newListLoading.value = true;
        const newList = await createList();
        await root.$router.push({name: 'ContactList', params: {id: newList._id}});
      } finally {
        newListLoading.value = false;
      }
    };

    const openList = (list: List) => {
      if (props.clickable) root.$router.push({name: 'ContactList', params: {id: list._id}});
    }

    const selected = computed(() => {
      if (!props.value) return [];
      return props.value.map(v => contactLists.value.find(l => l._id === v));
    });

    return {
      headers, user, contactLists, contactListsLoading, newListLoading, newContactList, openList, selected
    };
  },
});

