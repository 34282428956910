


















import {defineComponent, ref} from '@vue/composition-api';

import PollList from "@/components/PollList.vue";
import {createPoll} from "@/backend/poll";

export default defineComponent({
  name: 'Polls',
  components: {PollList},
  setup(_, {root}){
    const newPollLoading = ref(false);
    const newPoll = async (name: string) => {
      try{
        newPollLoading.value = true;
        const newPoll = await createPoll(name);
        await root.$router.push({name: 'PollEdit', params: {id: newPoll._id}});
      }finally {
        newPollLoading.value = false;
      }
    };
    return {newPollLoading, newPoll};
  }
});
