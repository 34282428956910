






import {defineComponent, ref, onMounted} from '@vue/composition-api';
import Quill from 'quill';

export default defineComponent({
  name: 'Editor',
  props: {
    value: {required: true, type: String},
    placeholder: String,
  },
  setup(props, {emit}) {
    const editor = ref();
    const html = props.value;
    onMounted(() => {
      const quill = new Quill(editor.value, {
        modules: {
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}, {font: []}, {size: ['16px', '20px']}],
            ['bold', 'italic', 'underline', {'color': []}, {'background': []}],
            // ['link', 'image', 'video'],
            ['link'],
            [{'align': []}],
            [{'list': 'bullet'}, {'list': 'ordered'}],
            [{'indent': '-1'}, {'indent': '+1'}],
            ['clean']
          ]
        },
        placeholder: props.placeholder,
        // theme: 'bubble',
        theme: 'snow',
      });
      quill.on('text-change', () => emit('input', quill.root.innerHTML));
      quill.root.addEventListener('blur', () => setTimeout(() => emit('blur'), 100));
    });
    return {editor, html};
  },
});

