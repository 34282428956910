














import {defineComponent, ref} from '@vue/composition-api';
import {deletePoll, Poll} from "@/backend/poll";

export default defineComponent({
  name: 'PollOptions',
  props: {
    value: {type: Object as () => Poll, required: true},
    large: Boolean
  },
  setup({value}, {emit}) {
    const loading = ref(false);
    const remove = async () => {
      loading.value = true;
      await deletePoll(value._id);
      emit('deleted');
      loading.value = false;
    }
    return {loading, remove};
  }
});
