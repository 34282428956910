import axios from "axios";
import {ContactList, Contact, ParticipantView} from "@noty/common";

export {ContactList, Contact, ParticipantView};

export async function createContact(contact: Contact){
    return (await axios.post<Contact>('contacts', contact)).data;
}

export async function importContacts(contacts: Contact[], list: string, overrideExisting: boolean){
    return (await axios.post<Contact[]>('contacts/import', contacts, {params: {list, overrideExisting}})).data;
}

export async function getContactLists() {
    return Object.values((await axios.get<Record<string, ContactList>>('contacts/lists')).data);
}

export async function getContactList(id: string) {
    return (await axios.get<ContactList>('contacts/lists/' + id)).data;
}

export async function getContactByEmail(email: string) {
    return (await axios.get<Contact | undefined>('contacts/email/' + email)).data;
}

export async function getContact(id: string) {
    return (await axios.get<Contact>('contacts/' + id)).data;
}

type ContactUpdate = { email?: string, firstName?: string, lastName?: string, patronimycName?: string, lists: string[]};
export async function updateContact(id: string, update: ContactUpdate) {
    return (await axios.patch<Contact>('contacts/' + id, update)).data;
}

