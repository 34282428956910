import XLSX from 'xlsx';

export type SheetRow = Record<string, string>;

export type ParsedSheet = {
    data: SheetRow[],
    headers: string[],
    htmlPreview: string
};

type ParsedSheets = {
    sheetNames: string[],
    sheets: Record<string, ParsedSheet>
}

export function readSpreadsheetFile(file: File): Promise<ParsedSheets> {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    return new Promise(resolve => {
        reader.onload = () => {
            //@ts-expect-error
            const rawData = new Uint8Array(reader.result);
            const workbook = XLSX.read(rawData, {type: 'array'});
            const parsed: ParsedSheets = {sheetNames: workbook.SheetNames, sheets: {}}
            workbook.SheetNames.forEach(function (name) {
                const data: SheetRow[] = XLSX.utils.sheet_to_json(workbook.Sheets[name]);
                const headers: Set<string> = new Set<string>();
                data.forEach((row: Record<string, any>) => {
                    Object.keys(row).forEach(header => headers.add(header));
                });
                const htmlPreview = XLSX.utils.sheet_to_html(XLSX.utils.json_to_sheet(data.slice(0, 10)));
                parsed.sheets[name] = {data, headers: Array.from(headers), htmlPreview};
            });
            resolve(parsed);
        }
    })
}

export function jsonToHtml(data: SheetRow[]) {
    return XLSX.utils.sheet_to_html(XLSX.utils.json_to_sheet(data));
}