























































































import { defineComponent, ref, Ref } from '@vue/composition-api';
import { getPoll, getPollParticipants, getPollVotes, PollView, sendPollInvites, updatePoll, Vote } from "@/backend/poll";
import ContactTable from "@/components/ContactTable.vue";
import { Contact, ParticipantView } from "@/backend/contact";
import { displayError } from "@/util/error";
import Editor from "@/components/Editor.vue";
import { savingPromise } from "@/util/savigIndicator";

export default defineComponent({
  name: 'PollParticipants',
  components: { Editor, ContactTable },
  props: {
    requireSummary: Boolean,
    title: String
  },
  setup(_, { root }) {
    const poll = ref<PollView>() as Ref<PollView>;
    const participants = ref<ParticipantView[]>() as Ref<ParticipantView[]>;
    const votes = ref<Vote[]>() as Ref<Vote[]>;
    const votedSet = ref(new Set());
    const pollLoading = ref(false);

    const refreshPoll = async () => {
      try {
        pollLoading.value = true;
        poll.value = await getPoll(root.$route.params.id);
        participants.value = await getPollParticipants(root.$route.params.id);
        votes.value = await getPollVotes(root.$route.params.id);
        votedSet.value = new Set(votes.value.map(vote => vote.contact.email));
      } finally {
        pollLoading.value = false;
      }
    }
    refreshPoll();
    const refresh = () => {
      window.location.reload();
    }
    const editMailHtml = ref(false);
    const saveMailHtml = async (event: Event) => {
      const mailHtml = poll.value.mailHtml;
      const updated = await savingPromise(updatePoll(poll.value._id, { mailHtml }));
      poll.value.mailHtml = updated.mailHtml;
    }

    const selecting = ref(false);
    const selected = ref<Contact[]>([]);
    const selectNone = async () => {
      selected.value = [];
    }
    const selectAll = async () => {
      const selectedSet = new Set(selected.value);
      participants.value.forEach(p => selectedSet.add(p));
      selected.value = Array.from(selectedSet);
    }
    const selectWithoutInvite = async () => {
      const selectedSet = new Set(selected.value);
      participants.value.filter(participant => !participant.invites.length).forEach(p => selectedSet.add(p));
      selected.value = Array.from(selectedSet);
    }
    const selectWithoutVote = async () => {
      const selectedSet = new Set(selected.value);
      participants.value.filter(participant => !votedSet.value.has(participant.email)).forEach(p => selectedSet.add(p));
      selected.value = Array.from(selectedSet);
    }
    const selectionControls = [
      { label: 'poll.selectAll', icon: 'mdi-check-all', color: 'success', action: selectAll },
      { label: 'poll.selectAllWithoutInvite', icon: 'mdi-mailbox-open', color: 'warning', action: selectWithoutInvite },
      { label: 'poll.selectAllNotVoted', icon: 'mdi-vote', color: 'warning', action: selectWithoutVote },
      { label: 'poll.selectNone', icon: 'mdi-checkbox-blank-off-outline', action: selectNone }
    ]

    const invitesSending = ref(false);
    const sendInvites = async () => {
      try {
        invitesSending.value = true;
        console.log(await sendPollInvites(poll.value._id, selected.value.map(c => c.email)));
        window.location.reload();
      } catch (e) {
        displayError('Failed to send emails');
      } finally {
        invitesSending.value = false;
      }
    }

    return {
      poll, participants, votes, votedSet, pollLoading, selecting, selected, selectionControls,
      editMailHtml, saveMailHtml, sendInvites, invitesSending, refreshPoll, refresh
    };
  },
});

