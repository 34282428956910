























































import {defineComponent, ref} from '@vue/composition-api';
import Date from '@/components/DateTime/Calendar.vue';
import AccountFilterIcon from "@/components/AccountFilterIcon.vue";
import ParticipantList from "@/components/ParticipantList.vue";
import {getCurrentUser} from "@/auth/identity";
import ConversationTime from "@/components/DateTime/ConversationTime.vue";
import ConversationOptions from "@/components/PollOptions.vue";
import {createPoll, getPolls, Poll} from "@/backend/poll";
import PollState from "@/components/PollState.vue";
import PollOptions from "@/components/PollOptions.vue";

export default defineComponent({
  name: 'PollList',
  components: {PollOptions, PollState, ConversationOptions, ConversationTime, ParticipantList, Date, AccountFilterIcon},
  props: {
    requireSummary: Boolean,
    title: String
  },
  setup(_, {root}) {
    const user = getCurrentUser();
    const polls = ref<Poll[]>();
    const pollsLoading = ref(false);
    const refreshPolls = async () => {
      try {
        pollsLoading.value = true;
        polls.value = await getPolls();
      } finally {
        pollsLoading.value = false;
      }
    }
    refreshPolls();

    const removePoll = (poll: Poll) => {
      polls.value = polls.value?.filter(p => p._id !== poll._id);
    }

    const newPollLoading = ref(false);
    const newPoll = async () => {
      try {
        newPollLoading.value = true;
        const newPoll = await createPoll();
        await root.$router.push({name: 'PollEdit', params: {id: newPoll._id}});
      } finally {
        pollsLoading.value = false;
      }
    };

    return {user, polls, pollsLoading, newPoll, removePoll};
  },
});

