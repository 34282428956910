







import {defineComponent} from '@vue/composition-api';
import moment from 'moment';

export default defineComponent({
  name: 'AccountFilterIcon',
});
