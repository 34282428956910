import axios from "axios";
import {Question} from "@noty/common";

export {Question};

export async function createQuestion(poll: string): Promise<Question> {
    return (await axios.post<Question>('questions/' + poll)).data;
}

export async function deleteQuestion(id: string): Promise<Question> {
    return (await axios.delete<Question>('questions/' + id)).data;
}

type QuestionUpdate = { title?: string, description?: string, options?: { text: string }[], selectable?: number }

export async function updateQuestion(id: string, update: QuestionUpdate) {
    return (await axios.patch<Question>('questions/' + id, update)).data;
}
