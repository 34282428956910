
















import {defineComponent, ref, onMounted} from '@vue/composition-api';
import Quill from 'quill';

export default defineComponent({
  name: 'Invites',
  props: {
    value: {required: true, type: Array}
  },
  setup(props) {
    const invites = props.value.length ? props.value : [{mail: {status: 'not_sent'}}];
    const status = {
      not_sent: {icon: 'mdi-mailbox-open', color: 'warning'},
      request: {icon: 'mdi-email-send', color: 'primary'},
      delivered: {icon: 'mdi-email-check', color: 'success'},
      unique_opened: {icon: 'mdi-email-open', color: 'primary'},
      click: {icon: 'mdi-email-open', color: 'success'},
      soft_bounce: {icon: 'mdi-email-alert', color: 'warning'},
      hard_bounce: {icon: 'mdi-email-off', color: 'error'},
      invalid_email: {icon: 'mdi-email-off', color: 'error'},
      error: {icon: 'mdi-email-alert', color: 'error'},
    }
    return {invites, status};
  }
});

