





import {defineComponent} from '@vue/composition-api';
import {timesArray} from "@/util/datetime";
import {rules} from "@/util/rules";

export default defineComponent({
  name: 'TimeInput',
  props: {
    value: {type: String, required: true},
    label: String
  },
  setup(_, {emit}) {
    const updateTime = (t:string) => {
      if(rules.time(t) === true) emit('input', t);
    }
    return {timesArray, rules, updateTime};
  },
});
