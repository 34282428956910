





























































































import {defineComponent, PropType, ref, computed} from '@vue/composition-api';
import {Question, updateQuestion} from "../backend/question";
import {asArray, selectContents} from "@/util/util";
import {savingPromise} from "@/util/savigIndicator";

export default defineComponent({
  name: 'Question',
  props: {
    editable: Boolean,
    votable: Boolean,
    votedOptions: {type: Array, default: () => []},
    showVotes: Boolean,
    value: {type: Object as PropType<Question>, required: true}
  },
  setup(props, {emit}) {

    const saveTitle = async (event: Event) => {
      const title = (event.target as HTMLElement).innerText.trim();
      if (title === props.value.title) return;
      const updated = await savingPromise(updateQuestion(props.value._id, {title}));
      props.value.title = updated.title;
    }

    const saveDescription = async (event: Event) => {
      const description = (event.target as HTMLElement).innerText.trim();
      if (description === props.value.description) return;
      const updated = await savingPromise(updateQuestion(props.value._id, {description}));
      props.value.description = updated.description;
    }

    const saveSelectable = async (selectable: number) => {
      props.value.selectable = Math.max(0, Math.min(props.value.options.length, selectable));
      await savingPromise(updateQuestion(props.value._id, {selectable: props.value.selectable}));
    }

    const editingOptionIndex = ref(0);
    const saveOptions = (event: Event) => {
      props.value.options[editingOptionIndex.value].text = (event.target as HTMLElement).innerText.trim();
      savingPromise(updateQuestion(props.value._id, {options: props.value.options}));
    }

    const removeOption = (index: number) => {
      props.value.options.splice(index, 1);
      savingPromise(updateQuestion(props.value._id, {options: props.value.options}));
    }

    const lastOption = ref();
    const addOption = () => {
      //@ts-expect-error
      props.value.options.push({});
      editingOptionIndex.value = props.value.options.length - 1;
      setTimeout(() => lastOption.value[0].focus());
    }

    const selected = ref([]);
    const onSelect = () => emit('change', asArray(selected.value));

    const votesSum = computed(() => {
      return props.value.options
          .map(option => option.votes)
          .reduce((partial_sum, option) => partial_sum + option, 0) / 100;
    });

    return {
      saveTitle, saveDescription, saveSelectable, editingOptionIndex, saveOptions, removeOption, lastOption, addOption,
      selected, onSelect, votesSum,
      selectContents
    }
  }
});
