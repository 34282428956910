














import {defineComponent, computed, PropType} from '@vue/composition-api';
import {Contact, ParticipantView} from "@/backend/contact";
import Invites from "@/components/Invites.vue";

export default defineComponent({
  name: 'ContactTable',
  components: {Invites},
  props: {
    items: {required: true, type: Array as PropType<(Contact | ParticipantView)[]>},
    value: {type: Array as PropType<(Contact | ParticipantView)[]>, default: () => []},
    selectable: Boolean,
    showInvites: Boolean,
    votedSet: Set as PropType<Set<string>>
  },
  setup(props) {
    const headers: {}[] = [
      {text: 'Email', value: 'email'},
      {text: 'First name', value: 'firstName'},
      {text: 'Last Name', value: 'lastName'},
      {text: 'Patronymic name', value: 'patronymicName'}
    ];
    if (props.showInvites) {
      headers.push({
        text: 'Invites', value: 'invites', sort: (a: any[], b: any[]) => {
          const statusA = a.length ? a[a.length - 1].mail.status : 'not_sent';
          const statusB = b.length ? b[b.length - 1].mail.status : 'not_sent';
          return statusA.localeCompare(statusB);
        }
      });
    }
    if (props.votedSet) headers.push({text: 'Voted', value: 'voted'});
    const data = computed(() => {
      if (!props.votedSet) return props.items;
      return props.items.map(contact => ({...contact, voted: props.votedSet?.has(contact.email)}));
    });
    return {headers, data};
  },
});

