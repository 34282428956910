export type ColorMap = Record<string, { text: string; background: string; }>

const colors = [
    {text: 'rgb(224,62,62)', background: 'rgb(251,228,228)'},
    {text: 'rgb(173,26,114)', background: 'rgb(244,223,235)'},
    {text: 'rgb(105,64,165)', background: 'rgb(234,228,242)'},
    {text: 'rgb(11,110,153)', background: 'rgb(221,235,241)'},
    {text: 'rgb(15,123,108)', background: 'rgb(221,237,234)'},
    {text: 'rgb(223,171,1)', background: 'rgb(251,243,219)'},
    {text: 'rgb(217,115,13)', background: 'rgb(250,235,221)'},
    {text: 'rgb(100,71,58)', background: 'rgb(233,229,227)'},
    {text: 'rgb(155,154,151)', background: 'rgb(235,236,237)'}
]

function getColor(index: number) {
    return colors[index % colors.length];
}

export function getColorMap(participants: string[]) {
    const map: ColorMap = {};
    participants.forEach((participant, index) => map[participant] = getColor(index));
    return map;
}
